@font-face {
  font-family: "LG Smart";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/LGSmart.woff") format("woff");
}
@font-face {
  font-family: "LG Smart";
  font-style: italic;
  font-weight: normal;
  src: url("fonts/LGSmart-Italic.woff") format("woff");
}
@font-face {
  font-family: "LG Smart";
  font-style: normal;
  font-weight: bold;
  src: url("fonts/LGSmart-Bold.woff") format("woff");
}
@font-face {
  font-family: "LG Smart";
  font-style: italic;
  font-weight: bold;
  src: url("fonts/LGSmart-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "LG Smart";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/LGSmartLight-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "LG Smart";
  font-style: normal;
  font-weight: 100;
  src: url("fonts/LGSmartLight-Light.woff") format("woff");
}

@font-face {
  font-family: "LGSmart_H";
  font-style: normal;
  font-weight: bold;
  src: url("fonts/LGSmHaB_v1.4_151215.woff") format("woff");
}
@font-face {
  font-family: "LGSmart_H";
  font-style: normal;
  font-weight: 100;
  src: url("fonts/LGSmHaL_v1.4_151215.woff") format("woff");
}
@font-face {
  font-family: "LGSmart_H";
  font-style: normal;
  font-weight: normal;
  src: url("fonts/LGSmHaR_v1.4_151215.woff") format("woff");
}
@font-face {
  font-family: "LGSmart_H";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/LGSmHaSB_v1.4_151215.woff") format("woff");
}




* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -ms-content-zooming: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  font-family: "LGSmart_H", "Segoe UI", "Microsoft Sans Serif", sans-serif;
  font-size: 10px;
  /*color: #fff;*/
  background-color: black;
  border: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: "LGSmart_H", "Segoe UI", "Microsoft Sans Serif", sans-serif;
  appearance: none !important;
  border: none !important;
  outline: none !important;
}

/*html{*/
/*  height: 101vh;*/
/*  overflow: hidden;*/
/*}*/

/*body, #root{*/
/*  height: 100%;*/
/*  overflow: hidden;*/
/*}*/

canvas {
  position: absolute;
  z-index: 2;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: none;
  /*background: black;*/
  display: flex;
  justify-content: center;
  align-content: center;
}

::-webkit-scrollbar {
  width: 8px; /* 세로축 스크롤바 길이 */
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #aaa;
  /*opacity: 30%;*/
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #eee;
  /*opacity: 30%;*/
}

@media screen and (max-width: 1280px), screen and (max-height: 768px) {
  html,
  body {
    font-size: 8px;
  }
}

/*@media screen and (max-width: 1024px), screen and (max-height: 616px){*/
/*  html, body {*/
/*    font-size: 5px;*/
/*  }*/
/*}*/

@media screen and (max-width: 640px), screen and (max-height: 320px) {
  html,
  body {
    font-size: 5px;
  }
}
