.swiper {
  width: 100%;
  height: 100%;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
} */

.swiper-pagination {
  /* border: 0px solid yellow; */
}

.swiper-pagination-bullet {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  bottom: 11rem;
  background-color: rgba(255, 255, 255, 0.4);
}
.swiper-pagination-bullet-active {
  background-color: white;
}

.swiper-container-rtl {
  padding: 2em;
  border: 1px solid red;
}

.swiper-button-prev {
  width: 58px;
  height: 58px;
  background-color: beige;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  left: 0;
  border: 1px solid tomato;
}

.swiper-button-next {
  border: 1px solid tomato;
}
