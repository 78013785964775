.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  top: -13vh;
  background-color: rgba(255, 255, 255, 0.4);
}
.swiper-pagination-bullet-active {
  background-color: white;
}
