section {
    width: 100%;
    height: 100%;
}

section h1 {
    visibility: hidden;
    position: absolute;
}

section > .statusMsg {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

#canvas-container {
    position: absolute !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.hidden {
    display: none;
}
